import { ROUTE_PATHS } from './routes-paths';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
import { VpRoute } from '@zeiss/ng-vis-vp-auth/types';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { VisIcon } from '@zeiss/ng-vis-common/types';

export const ROUTE_CONFIG: VpRoute = {
	path: ROUTE_PATHS._,
	canActivate: [AUTH_GUARD],
	data: {
		title: 'vp.status.title',
		description: 'vp.status.desc',
		icon: VisIcon.COMMENT,
		showInMenu: true,
		rule: vpRule({
			rule: RULE.access_status,
			required: [],
		}),
		routes: [
			{
				path: '',
			},
			{
				path: ROUTE_PATHS.statustext,
				canActivate: [AUTH_GUARD],
				data: {
					title: 'vp.status.routes.statustext.title',
					description: 'vp.status.routes.statustext.desc',
					icon: VisIcon.TEXTUAL_VIEW,
					showInMenu: true,
					cache: true,
					rule: vpRule({
						rule: RULE.access_status,
						required: ['text'],
					}),
				},
			},
			{
				path: ROUTE_PATHS.statusmap,
				canActivate: [AUTH_GUARD],
				data: {
					title: 'vp.status.routes.statusmap.title',
					description: 'vp.status.routes.statusmap.desc',
					icon: VisIcon.SYSTEM_PARAMETERS,
					showInMenu: true,
					cache: true,
					rule: vpRule({
						rule: RULE.access_status,
						required: ['map'],
					}),
				},
			},
		],
	},
};
